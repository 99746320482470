import React from "react";
import img2 from "../../../src/Images/logo.png";
import iconPayStore from "../images/badge-en.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faMobileScreen,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import '../Styles/footer.scss'
import { Company, BankDetails } from "../Data";

function Footer() {
  console.log(BankDetails);

  return (
    <>
      <footer
        className="footer pt-5"
        style={{
          bottom: 0,
          // position: "fixed",
          width: "100%",

          boxShadow: "rgba(0, 0, 0, 0.15) 0px -7px 1rem 4px",
        }}
      >
        <div className="container">
          <div className="row m-0 footer-info">
            <div className="col-md-5 col-sm-12">
              <ul className="footer-list">
                <li className="mb-5 footer-logo">
                  <img src={img2} alt="Mudraa Pharma LLP logo" />
                </li>
                <li className="mb-4 small-devices-text sm-flex-center">
                  <table className="bank-details">
                    <tbody>
                      {BankDetails.map((item, index) => (
                        <tr key={index}>
                          <td>{item.label}</td>
                          <td>{item.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-12">
              <h4 className="small-devices-text">USEFUL LINKS</h4>

              <ul className="footer-list">
                <li>
                  <p className="small-devices-text">
                    <a href="/#Home">Home</a>
                  </p>
                </li>
                <li>
                  <p className="small-devices-text">
                    <a href="/ProductList">Products</a>
                  </p>
                </li>
                <li>
                  <p className="small-devices-text">
                    <a href="/#AboutUs">About Us</a>
                  </p>
                </li>
                <li>
                  <p className="small-devices-text">
                    <a href="/#OurVision">Our Vision</a>
                  </p>
                </li>
                <li>
                  <p className="small-devices-text">
                    <a href="/#OurValues">Our Values</a>
                  </p>
                </li>
                <li>
                  <p className="small-devices-text">
                    <a href="/#ContactUs">Contact Us</a>
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-sm-12">
              <h4 className="small-devices-text">CONTACT US</h4>
              <ul className="footer-list">
                <li>
                  <FontAwesomeIcon icon={faHouse} />
                  <p
                    className="address-section small-devices-text mr-1"
                    dangerouslySetInnerHTML={{ __html: Company[0].address }}
                  ></p>
                </li>

                <li className="email small-devices-text">
                  <FontAwesomeIcon icon={faEnvelope} />
                  <p className="mr-1">{Company[0].email}</p>
                </li>

                <li>
                  <FontAwesomeIcon icon={faMobileScreen} />
                  <p className="phone small-devices-text mr-1">
                    CONTACT: {Company[0].phone}
                  </p>
                </li>

                <li>
                  <FontAwesomeIcon icon={faMobileScreen} />
                  <p className="phone small-devices-text mr-1">
                    CONTACT: {Company[0].alternateNumber}
                  </p>
                </li>
              </ul>
            </div>

            <section className="copyright">
              <p className="small-devices-text">
                Powered By:{" "}
                <a
                  href="http://www.relysoft.in/"
                  target="_blank"
                  rel="noreferrer"
                  className="powered-by text-sm-center"
                >
                  relysoft.in
                </a>
              </p>
            </section>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
