// import R_Logo from './R_Logo.png'
import { Link, useHistory } from "react-router-dom";
import "../../../components/customer/Customer.css";
import { useState, useRef, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../../store/user-slice.js";
import TopHeader from "../TopHeader.js";


export default function HeaderCustomer() {
  const [visible, setVisible] = useState(false);
  const [showUserDetail, setShowUserDetail] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  //const history = useHistory();
  function logout() {
    window.localStorage.removeItem("user-info");
    window.localStorage.removeItem("app-type");

    history.replace("/Customer");
    dispatch(userActions.setLogin(false));
  }
  const refOne = useRef(null);

  useEffect(() => {
    // document.addEventListener("click", hideOnClickOutside, true)
    // return () => {
    //     document.removeEventListener("click", hideOnClickOutside, true)
    // }
  }, []);

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      console.log("Outside");
      setVisible(false);
    }
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (event) => {
    const { scrollLeft } = event.target;
    setScrollPosition(-scrollLeft);
  };

  const userInfo = useMemo(
    () =>
      localStorage.getItem("user-info")
        ? JSON.parse(localStorage.getItem("user-info"))
        : null,
    []
  );
  return (
    <>
      <div className=" mainLogin">
        <TopHeader />
      </div>

      <div className="w-100 loggedin-user-info">
        <div className="d-flex justify-content-end align-items-center py-0 px-3">
          <div className="py-2">
            <div className="text-end">
              {userInfo ? "Welcome: " + userInfo.name : ""}{" "}
              {userInfo ? "  " + userInfo.place : ""}
            </div>
          </div>
        </div>
      </div>

      <div className="Cust-Order-list" onScroll={handleScroll}>
        <Link
          to="/components/customer/CustomerDashboard"
          style={{ textDecoration: "none" }}
        >
          <li>Order</li>
        </Link>

        <Link
          to="/components/customer/OrderByComp_Customer"
          style={{ textDecoration: "none" }}
        >
          <li>Order by Comp </li>
        </Link>
        <span
          onMouseLeave={() => {
            // console.log("Leaved");
            setVisible(false);
            // setShowUserDetail(true)
          }}
        >
          <li
            onMouseEnter={() => {
              // console.log("Entered");
              setVisible(true);
              setShowUserDetail(false);
            }}
            to=" "
            onClick={() => {
              // console.log({ showUserDetail, visible });
              setShowUserDetail(!showUserDetail);
              setVisible(!visible);
            }}
            style={{
              color: "black",
              marginTop: "0px",
              padding: "10px",
            }}
          >
            Reports
          </li>

          {visible && (
            <div
              className="Dreport_list"
              onMouseLeave={() => setVisible(false)}
              ref={refOne}
              style={{
                transform: `translateX(${scrollPosition}px)`,
              }}
            >
              <Link
                to="/components/customer/Ledger"
                style={{ textDecoration: "none", color: "white" }}
              >
                <p
                  className="list_reports"
                  style={{ padding: "5px 0px 5px 10px" }}
                >
                  View ledger
                </p>
              </Link>
              <Link
                to="/components/customer/PendingBills"
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                <p
                  className="list_reports"
                  style={{ padding: "5px 0px 5px 10px" }}
                >
                  Pending bills{" "}
                </p>
              </Link>
              <Link
                to="/components/customer/DownLoadInvoice"
                style={{ textDecoration: "none", color: "white" }}
              >
                <p
                  className="list_reports"
                  style={{ padding: "5px 0px 5px 10px" }}
                >
                  {" "}
                  Download <br />
                  Invoice
                </p>
              </Link>
              <Link
                to="/components/customer/OrderList"
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                <p
                  className="list_reports"
                  style={{ padding: "5px 0px 5px 10px" }}
                >
                  Order List
                </p>
              </Link>
              <Link
                to="/components/customer/BounceList"
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                <p
                  className="list_reports"
                  style={{ padding: "5px 0px 5px 10px" }}
                >
                  Bounce List{" "}
                </p>
              </Link>
              <Link
                to="/components/customer/BillTracking"
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                <p
                  className="list_reports"
                  style={{ padding: "5px 0px 5px 10px" }}
                >
                  Bill Tracking
                </p>
              </Link>
            </div>
          )}
        </span>

        <span
          onMouseLeave={() => {
            setShowUserDetail(false);
          }}
        >
          <div
            className="user"
            style={{
              textDecoration: "none",
              listStyle: "none",
            }}
            onMouseEnter={() => {
              console.log("User over");
              setShowUserDetail(true);
              setVisible(false);
            }}
            onClick={() => {
              setShowUserDetail(!showUserDetail);
            }}
          >
            <li>User</li>
          </div>
          {showUserDetail && (
            <li
              style={{
                transform: `translateX(${scrollPosition}px)`,
              }}
              onClick={logout}
              className="logOut_customer"
              onMouseLeave={() => setShowUserDetail(false)}
              ref={refOne}
            >
              Log Out
            </li>
          )}
        </span>
      </div>
    </>
  );
}
