import React from "react";
import { Col, Image, Row, Space } from "antd";
import member1 from '../../Images/members/Image_(1).jpg';
import member2 from '../../Images/members/Image_(2).jpg';
import member3 from '../../Images/members/Image_(3).jpg';
import member4 from '../../Images/members/Image_(4).jpg';
import member5 from '../../Images/members/Image_(5).jpg';
import member6 from '../../Images/members/Image_(6).jpg';
import member7 from '../../Images/members/Image_(7).jpg';
import member8 from '../../Images/members/Image_(8).jpg';
import member9 from '../../Images/members/Image_(9).jpg';
import member10 from '../../Images/members/Image_(10).jpg';
import member from '../../Images/members/Image.jpg';


const imageHeight = "300px";
const imageWidth = "300px";
const columnSpan = 8;
const imageStyle = {
  objectFit: "cover",
  alignSelf: "center",

  borderRadius: "300px",
};
const columnStyle = {
  textAlign: "center",
  padding: 5
};

const nameTextClass = "mb-5 mt-2";
const AboutUs = () => {
  console.log("About Us");
  return <div className="container pt-4">
    <Row>
      <Col>


        <div className="large-bold">

          At mudraa pharma venture we constantly work towards
          ensuring access to good quality and best medicines to our
          chemists .
          <br />

          We have been trusted by chemists and health care
          professionals for the last 35 years .
        </div>
        <br />

        <h1 className="text-center " style={{
          // fontSize: 20,
          paddingTop: 20,
          paddingBottom: 50
        }}>
          Board of Directors
        </h1>
        <Row>
          <Col span={columnSpan} style={{ textAlign: "center" }}>
            <Space size={12} align="center">
              <Image
                width={imageWidth}
                style={{
                  ...imageStyle,
                }}
                height={imageHeight}
                src={member}
              />
            </Space>
            <h4 className={nameTextClass}>
              Atul Kotalwar
            </h4>
          </Col>
          <Col span={columnSpan} style={columnStyle}>
            <Space size={12}>
              <Image
                width={imageWidth}
                style={imageStyle}
                height={imageHeight}
                src={member1}
              />
            </Space>
            <h4 className={nameTextClass}>
              Ramdas  Bhosle
            </h4>
          </Col>
          <Col span={columnSpan} style={columnStyle}>
            <Space size={12}>
              <Image
                width={imageWidth}
                style={imageStyle}
                height={imageHeight}
                src={member2}
              />
            </Space>
            <h4 className={nameTextClass}>
              Deepak Warad
            </h4>
          </Col>
          <Col span={columnSpan} style={columnStyle}>
            <Space size={12}>
              <Image
                style={imageStyle}
                width={imageWidth}
                height={imageHeight}
                src={member5}
              />
            </Space>
            <h4 className={nameTextClass}>
              Ganesh Kotalwar
            </h4>
          </Col>
          <Col span={columnSpan} style={columnStyle}>
            <Space size={12}>
              <Image
                width={imageWidth}
                height={imageHeight}
                style={imageStyle}
                src={member3}
              />
            </Space>
            <h4 className={nameTextClass}>
              Sushma Warad
            </h4>
          </Col>
          <Col span={columnSpan} style={columnStyle}>
            <Space size={12}>
              <Image
                width={imageWidth}
                style={imageStyle}
                height={imageHeight}
                src={member4}
              />
            </Space>
            <h4 className={nameTextClass}>
              Sanjay Karwa
            </h4>
          </Col>

          <Col span={columnSpan} style={columnStyle}>
            <Space size={12}>
              <Image
                width={imageWidth}
                style={imageStyle}
                height={imageHeight}
                src={member6}
              />
            </Space>
            <h4 className={nameTextClass}>
              Ganesh Bhosle
            </h4>
          </Col>
          <Col span={columnSpan} style={columnStyle}>
            <Space size={12}>
              <Image
                width={imageWidth}
                style={imageStyle}
                height={imageHeight}
                src={member7}
              />
            </Space>
            <h4 className={nameTextClass}>
              Manoj Agase
            </h4>
          </Col>
          <Col span={columnSpan} style={columnStyle}>
            <Space size={12}>
              <Image
                width={imageWidth}
                style={imageStyle}
                height={imageHeight}
                src={member8}
              />
            </Space>
            <h4 className={nameTextClass}>
              Sagar Mantri
            </h4>
          </Col>
          <Col span={columnSpan} style={columnStyle}>
            <Space size={12}>
              <Image
                width={imageWidth}
                style={imageStyle}
                height={imageHeight}
                src={member9}
              />
            </Space>
            <h4 className={nameTextClass}>
              Arun Somani
            </h4>
          </Col>
          <Col span={columnSpan} style={columnStyle}>
            <Space size={12}>
              <Image
                width={imageWidth}
                style={imageStyle}
                height={imageHeight}
                src={member10}
              />
            </Space>
            <h4 className={nameTextClass}>
              Vikas Somani
            </h4>
          </Col>
        </Row>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Col>
    </Row>
  </div>;
};

export default AboutUs;
