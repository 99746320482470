import { Col, Row } from 'antd'
import React from 'react'

const OurValues = () => {
    return (
        <div className="container pt-4">
            {/* <h3>
                Our Values
            </h3> */}
            <Row>
                <Col span={24} >
                    <p className='large-bold ' style={{

                    }}>

                        In pursuit of providing affordable and best goods
                        towards our chemists ,
                        we will create an environment of good team work and
                        best services for higher level of excellence .


                    </p>

                </Col>
                <Col span={24}>

                    <ul className=' normal-bold' style={{
                        listStyleType: "square",
                        // listStyleType: "none",

                    }}>
                        <li>
                            Integrity and Transparency
                            We will uphold the higher standards of integrity and
                            transparency in all transactions ,
                        </li>
                        <li>
                            SAFETY
                        </li>
                        <li>
                            QUALITY
                        </li>

                        <li>
                            PRODUCTIVITY
                        </li>

                        <li>
                            RESPECT FOR THE INDIVIDUAL
                        </li>

                        <li>
                            COLLABORATION AND TEAMWORK
                        </li>

                        <li>
                            SUSTAINABILITY
                        </li>

                    </ul>
                </Col>
            </Row>


        </div>
    )
}

export default OurValues