const Company = [
  {
    name: "MUDRAA PHARMA LLP",
    fullName: "MUDRAA PHARMA VENTURE LLP",
    phone: "+91-9356935284",
    alternateNumber: "+91-9356940497",
    email: "mudraapharmallp@gmail.com",
    address:
      "MUDRAA PHARMA VENTURE LLP, <br/>OPPOSITE TO SHIVAJI SCHOOL KANHERI ROAD LATUR, 413512",
  },
];

const BankDetails = [
  {
    label: "Bank Details:",
    value: "ICICI BANK",
  },
  {
    label: "Branch:",
    value: "LATUR BRANCH",
  },
  {
    label: "A/C. No.:",
    value: "034105009591",
  },
  {
    label: "IFSC Code:",
    value: "ICIC0000341",
  },
];

export { Company, BankDetails };