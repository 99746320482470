import React, { useRef } from "react";
import CustomerFooter from "../../assets/snippets/Customer/customFooter";
import './Customer.css';
import HeaderCustomer from "../../assets/snippets/Customer/HeaderCustomer";
import { Table } from "react-bootstrap";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-calendar/dist/Calendar.css';
import * as XLSX from 'xlsx';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ApiHandler from "../../ApiHandler";
import TransferData from "../../TransferData";
import { stubString } from "lodash";
import { addDays, format } from "date-fns";
import moment from "moment";
import { Triangle } from "react-loader-spinner";
import { Button, DatePicker } from "antd";
import dayjs from "dayjs";
import LoaderTri from "../Loader/LoaderTri";



const initialValues = {
    from: dayjs(),
    to: dayjs()
};
export default function Ledger() {
    const [dates, setDates] = useState(initialValues);

    const [showLedgerData, setShowLedgerData] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [allDatesData, setAllDatesData] = useState([])
    const [openDate, setOpenDate] = useState(false)
    const [viewdata, setViewdata] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [range, setRange] = useState([{
        startdate: new Date(),
        endDate: addDays(new Date(), ''),
        key: 'selection'
    }])

    function handleExportDataExcel() {
        console.log(showLedgerData);
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(showLedgerData);
        XLSX.utils.book_append_sheet(wb, ws, "ledger");
        XLSX.writeFile(wb, "Report.xlsx");
    }




    function toViewData() {
        setViewdata(true)
        setIsLoading(true)
        setShowLedgerData([])
        const localUrl = JSON.parse(localStorage.getItem('LocalURL'))
        const user = JSON.parse(localStorage.getItem('user-info'))
        console.log(localUrl);

        axios({

            url: localUrl + "api/OwnApp/GetLedger",
            method: "POST",
            data: {
                // startdate: moment(selectionRange.startDate).format("YYYYMMDD"),
                // enddate: moment(selectionRange.endDate).format("YYYYMMDD"),
                code: user.code,
                startdate: dayjs(dates.from).format("YYYYMMDD"),
                enddate: dayjs(dates.to).format("YYYYMMDD"),
            },
            proxy: {
                protocol: "http",

            }
        })
            .then(((result) => {
                console.log(result);
                console.log(result.data);
                setShowLedgerData(result.data)
                setAllDatesData(result.data)
            }))
            .catch(err => {
                console.error(err);
                if (err.response && err.response.status && err.response.status === 401) {
                    console.log(err.response);
                    //   RefreshTokenHandler();
                    //  ToastError("Token has expired Please refresh");
                }
            })
            .finally(() => {
                setIsLoading(false)
            })





    }






    const refOne = useRef(null);

    useEffect(() => {
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, true)
        return () => {
            document.removeEventListener("keydown", hideOnEscape, true)
            document.removeEventListener("click", hideOnClickOutside, true)
        }
    }, []);

    const hideOnEscape = (e) => {
        //    console.log(e.key)
        if (e.key === "Escape") {
            setOpenDate(false)
        }
    }
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpenDate(false)
        }
    }


    const handleSelects = (date) => {

        let filtered = allDatesData.filter((product) => {

            let productDate = new Date(product["date"]);
            return (
                productDate >= date.selection.startDate &&
                productDate <= date.selection.endDate

            );
        });
        setStartDate(date.selection.startDate);
        //  setStartDate(moment(date.selection.startDate).format("DD-MM-YYYY"));
        setEndDate(date.selection.endDate);
        setShowLedgerData(filtered);
        console.log(moment(date.selection.startDate).format("DD-MM-YYYY"))
        console.log(date.selection.startDate);
        console.log(date.selection.endDate);
    };

    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: "selection",
    };
    // startdate: moment(startDate).format("YYYYMMDD"),
    // endDate: moment(endDate).format("YYYYMMDD"),
    // key: "selection",


    return (
        <>
            <HeaderCustomer />
            <div style={{ minHeight: window.innerHeight / 1.4 }}>

                <div className="row m-0">
                    <div className="col-lg-10 offset-lg-1  ">
                        <h5 style={{
                            marginTop: "20px",
                            fontFamily: "sans-serif",
                            // fontSize: "17px",



                        }} >Select details to view Ledger</h5 >

                        <div className="row ">
                            <div className="col-lg-3  ">
                                <DatePicker.RangePicker
                                    size="middle"
                                    className=" mb-0 mr-2"
                                    value={[dates.from, dates.to]}
                                    format="DD-MM-YYYY"
                                    onChange={(e) => {
                                        console.log(e);

                                        if (e) {
                                            setDates({
                                                from: e[0],
                                                to: e[1],
                                            });
                                        } else {
                                            setDates({ from: null, to: null });
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-lg-2  ">
                                <Button
                                    type="button"
                                    className="btn   btn-primary btn-sm"
                                    onClick={toViewData}
                                >View</Button>

                            </div>
                        </div>

                        {/* <div className="enterDateInput  ">
                            <form >
                                <input
                                    required
                                    // value={`${format(range[0].startdate, 'MM-dd-yyyy')}  to  ${format(range[0].endDate, 'MM-dd-yyyy')} `}
                                    value={`${moment(selectionRange.startDate).format("DD-MM-YYYY")} to ${moment(selectionRange.endDate).format("DD-MM-YYYY")}`}
                                    readOnly
                                    onClick={() => setOpenDate(true)}
                                    style={{
                                        width: "300px",
                                        paddingLeft: '30px'

                                    }}

                                />

                                <button
                                    type="button"
                                    onClick={toViewData}

                                >View</button>
                            </form>
                        </div>

                        <div className="dateRangePicker" ref={refOne}>
                            {
                                openDate &&
                                <DateRange
                                    onChange={handleSelects}
                                    editableDateInputs={true}
                                    moveRangeOnFirstSelection={false}
                                    ranges={[selectionRange]}
                                    months={2}
                                    direction="horizontal"
                                    showMonthAndYearPickers={false}
                                    className="DaterangePicker"
                                />
                            }
                        </div> */}

                        {
                            viewdata &&
                            <div className="Ledger_table">
                                <div className="Ledger_Data p-0">

                                    <h6>Ledger</h6>
                                    {showLedgerData.length > 0 && <button onClick={handleExportDataExcel}>
                                        Download
                                    </button>}
                                    {/* <Tables TransferData={TransferData} showledgerData={showledgerData} /> */}


                                    <div style={{
                                        width: "100%",
                                        overflowX: "scroll"
                                    }}>
                                        <Table striped>
                                            <thead className="threAd">
                                                <tr className="threAd_tr">
                                                    <th>{TransferData.date}</th>
                                                    <th style={{ minWidth: "150px" }}>{TransferData.particulars}</th>
                                                    <th style={{ minWidth: "200px" }}>{TransferData.billNo}</th>
                                                    <th style={{ textAlign: "right" }}>{TransferData.debit}</th>
                                                    <th style={{ textAlign: "right" }}>{TransferData.credit}</th>
                                                    <th style={{ textAlign: "right" }}>{TransferData.balance}</th>
                                                    <th style={{ textAlign: "right" }}>{TransferData.bDisAmt}</th>
                                                </tr>
                                            </thead>
                                            {

                                                showLedgerData.map((elm, index) => {
                                                    return (<tbody className="tboDY" key={index} >
                                                        <tr style={{ backgroundColor: elm._Color === "Y" ? 'red' : ' ', }} >
                                                            <td >{elm.date}</td>
                                                            <td>{elm.particulars}</td>
                                                            <td>{elm.billNo}</td>
                                                            <td style={{ textAlign: "right" }} >{elm.debit}</td>
                                                            <td style={{ textAlign: "right" }}>{elm.credit}</td>
                                                            <td style={{ textAlign: "right" }}>{elm.bDisAmt}</td>
                                                            <td style={{ textAlign: "right" }}>{elm.balance}</td>
                                                        </tr>
                                                    </tbody>)
                                                })

                                            }
                                        </Table>
                                    </div>
                                    {isLoading &&
                                        <div className="d-flex justify-content-center" style={{
                                            // marginLeft: "700px"

                                        }}>
                                            <LoaderTri />


                                        </div>
                                    }


                                </ div >
                            </div >
                        }

                    </div>
                </div>

            </div>
            <CustomerFooter />
        </>
    )
}