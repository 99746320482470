import React from "react";
import Slider from "./Slider";
import MarqueeLComList from "./MarqueeLComList";
import { BackTop } from "antd";

const HomeComponent = () => {
  return (
    <div className="row m-0 w-100">
      <div className="col-lg-12 p-0">
        <div className="text-end m-0" style={{}}>
          <Slider />
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
