import { Col, Row } from 'antd'
import React from 'react'

const OurVision = () => {
    return (
        <div className="container pt-4">
            <Row>
                <Col>

                    {/* <h3   >
                        Our Vision
                    </h3 > */}

                    <h6 className='large-bold'>

                        Reaching towards our valuable chemists and touching
                        lives globally as a leading provider of brand and valued
                        goods.
                    </h6>

                </Col>
            </Row>
        </div>
    )
}

export default OurVision