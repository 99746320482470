import img2 from "../Images/logo.png";
import R_Logo from "../Images/R_Logo.png";
import { Link, useHistory } from "react-router-dom";
import "../App.css";
import Pfooter from "../Pfooter";
import { useState } from "react";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalVariable from "../GlobalVariable";
import ApiHandler from "../ApiHandler";
import HeaderImage from "../Images/HeaderImage";
import { useDispatch } from "react-redux";
import { userActions } from "../store/user-slice";

export default function Customer() {
  const dispatch = useDispatch();
  const [data, setData] = useState({ username: "", password: "" });
  const [reports, setReports] = useState(false);
  const [response, setResponse] = useState("");

  const changeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const history = useHistory();

  async function checkUser(e) {
    e.preventDefault();
    let item = {
      userId: data.username,
      password: data.password,
      wclientid: GlobalVariable.wcClient.toString(),
    };
    // let token="";
    ApiHandler.post({
      url: "api/Cust/Authenticate/AuthTokenCust/",
      data: item,
    })
      .then((result) => {
        // console.log(result.data.token);
        setResponse(result.data.reason)

        if (result.data.isSuccess) 
        {
          // history.push("/components/customer/CustomerDashboard");
          window.localStorage.setItem("app-type", "Cust");
          dispatch(userActions.setLogin(true));
          localStorage.setItem("user-info", JSON.stringify(result.data));
          // window.localStorage.setItem("loggedIn", true);

          setTimeout(() => {
            history.push("/components/customer/CustomerDashboard");
          });
        } 
        else
        {
          toast.error(result.data.reason);
          throw result.data;
        }
      })
      .catch((err) => {
        // toast.error("wrong username or password");
        
        console.error(err);
        if (
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          console.log(err.response);
          //   RefreshTokenHandler();
          //  ToastError("Token has expired Please refresh");
        }
      });
  }

  return (
    <>
      <div className="customer_header">
        <Link to="/">
          <HeaderImage />
        </Link>
        {/* <h5
          style={{
            color: "#00C0F0",
            fontWeight: "700",
            marginTop: "15px",
          }}
        >
          ASHISH MEDILINK LLP
        </h5> */}
        <img
          src={R_Logo}
          height={50}
          width={50}
          className="R"
          alt=""
          style={{ marginTop: "0px" }}
        />
      </div>

      <form onSubmit={checkUser}>
        <div className="row m-0">
          <div className="col-lg-6 offset-lg-3 p-2  mb-2">
            <div className="card mt-4 ">
              <h5
                style={{
                  backgroundColor: "rgb(242, 241, 241)",
                  fontSize: "25px",
                  fontWeight: "300",
                  padding: "10px",
                }}
              >
                Customer Login
              </h5>
              <div className="p-2">
                <label
                  style={{
                    fontSize: "20px",
                    fontWeight: "650",
                  }}
                >
                  Customer Id
                </label>
                <input
                  name="username"
                  onChange={changeHandler}
                  value={data.username}
                  type="text"
                  className="form-control"
                  placeholder="Customer ID"
                />

                <label
                  style={{
                    fontSize: "20px",
                    fontWeight: "650",
                  }}
                >
                  Password
                </label>
                <br />
                <input
                  onChange={changeHandler}
                  name="password"
                  value={data.password}
                  type="Password"
                  className="form-control"
                  placeholder="Password "
                />
              </div>
              <button
                type="submit"
                className="btns"
                //onClick={() => dispatch(add(reports))}
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
      </form>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Pfooter />
    </>
  );
}
